.custom-accordion {
  padding-top: 4.313rem;

  .accordion-arrow-btn {
    background-color: transparent;
    border: 0;
    color: var(--#{$variable-prefix}light-aluminum);
  }

  .accordion-item {
    background-color: transparent;
    border: 0;
    &.active-render{
      border: 0.125rem solid var(--#{$variable-prefix}primary-color);
    }
  }
  .accordion-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
    padding: 1rem;
    border-bottom: 0.063rem solid var(--#{$variable-prefix}midnight-gray);
    background: var(--#{$variable-prefix}bg-dark-gray);
    &.active {
      background-color: var(--#{$variable-prefix}bg-dark-navy);
      font-weight: 700;
      .accordion-detail-title-wrap{
        .detail-project-title{
          
          font-weight: 700;
        }
      }
    }
    &.header-active{
        .editable-content{
          .editable-title{
            font-weight: 700;
          }
        }
    }
  }

  .accordion-body {
    background-color: var(--#{$variable-prefix}bg-dark-navy);
    padding: 1rem;
  }

  .btn-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1.25rem;
  }
}

.light-accordion {
   margin-bottom: 1.25rem;
}
.light-accordion-header {
  display: flex;
  padding: 1.125rem 0;
  justify-content: space-between;
  align-items: center;
  gap: 0.625rem;
  align-self: stretch;
  border-top: 0.063rem solid var(--#{$variable-prefix}bg-cloud-gray);
  border-bottom: 0.063rem solid var(--#{$variable-prefix}bg-cloud-gray);
  background: var(--#{$variable-prefix}background-white);
  cursor: pointer;
  h4 {
    color: var(--#{$variable-prefix}charcoal-gray);
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.005rem;
  }
}

.light-accordion-content {
  padding: 1.25rem 0 0;
  transition: max-height 0.3s ease;
}

.light-accordion-wrap {
  .custom-color-input {
    margin: 0;
  }
}
