.assets-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.875rem;
  padding: 2.5rem;
  min-height: calc(100vh - 12.75rem);
  overflow-y: auto;
}

.assets-status-box-holder {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 1.875rem;
}

.assets-status-box {
  display: flex;
  width: 15.125rem;
  padding: 0.875rem 1.25rem;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 0.5rem;
  background-color: var(--#{$variable-prefix}background-white);
  border-radius: 0.25rem;
  border: 0.063rem solid var(--#{$variable-prefix}bg-cloud-gray);
  box-shadow: 0 0 0.375rem 0 rgba(0, 0, 0, 0.25);
  transition:
    all 0.3s ease,
    border-color 0.3s ease,
    font-weight 0.3s ease;
  cursor: pointer;
  &:hover {
    border-color: var(--#{$variable-prefix}bg-soft-plum-gray);
    .files-wrap {
      .file-name {
        font-weight: 700;
      }
    }
  }
  &.active {
    border-color: var(--#{$variable-prefix}primary-color);
    .files-wrap {
      .file-name {
        font-weight: 700;
      }
    }
  }
  .files-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    svg {
      width: 1.5rem;
      height: 1.5rem;
    }
    .file-name {
      color: var(--#{$variable-prefix}soft-plum-gray);
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-align: left;
      flex: 1;
    }
  }
  .detail-wrap {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.625rem;
    .files-size {
      font-size: 0.875rem;
      font-style: normal;
      line-height: normal;
      font-weight: 400;
      color: var(--#{$variable-prefix}soft-plum-gray);
    }
    .files-type {
      @extend .files-size;
      font-weight: 500;
      color: var(--#{$variable-prefix}charcoal-gray);
    }
  }
}

.assets-container {
  width: 100%;
  display: flex;
  align-items: flex-start;
  gap: 1.875rem;
  transition: all 0.3s ease-in-out;
  h2 {
    color: var(--#{$variable-prefix}charcoal-gray);
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.015rem;
  }
  .assets-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.875rem;
    flex-grow: 1;
    .assets-header {
      height: 40px;
    }
    .delete-icon {
      color: var(--#{$variable-prefix}charcoal-gray);
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 8px 16px;
      border: 1px solid transparent;
      border-radius: 4px;
      transition: all 0.3s ease-out;
      font-size: 14px;
      line-height: 170%;
      height: 100%;
      svg {
        width: 16px;
        height: 16px;
        g {
          path {
            fill: var(--#{$variable-prefix}charcoal-gray);
          }
        }
      }
      &:hover {
        color: var(--#{$variable-prefix}primary-color);
        border: 1px solid var(--#{$variable-prefix}primary-color);
        font-weight: 600;
        svg {
          g {
            path {
              fill: var(--#{$variable-prefix}primary-color);
            }
          }
        }
      }
    }
    .data-not-found-wrapper {
      width: 100%;
      min-height: auto;
      max-height: auto;
    }
  }
  .assets-sidebar {
    display: flex;
    width: 30.625rem;
    min-height: calc(100vh - 24.563rem);
    flex-shrink: 0;
    padding-left: 1.875rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.875rem;
    align-self: stretch;
    border-left: 0.063rem solid var(--#{$variable-prefix}bg-cloud-gray);
    background: var(--#{$variable-prefix}background-white);
  }
}

.settung-sharing-wrap {
  display: flex;
  align-items: center;
  gap: 0.625rem;
  align-self: stretch;
  .info-wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.375rem;
    flex: 1 0 0;
    h4 {
      color: var(--#{$variable-prefix}charcoal-gray);
      font-size: 1rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.005rem;
    }
    span {
      @extend h4;
      font-weight: 400;
      color: var(--#{$variable-prefix}soft-plum-gray);
    }
    p {
      color: var(--#{$variable-prefix}soft-plum-gray);
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.004rem;
    }
  }
}

.assets-checkbox-holder {
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 1.875rem;
  align-self: stretch;
  flex-wrap: wrap;
}
.assets-checkbox-block__wrapper {
  width: 15rem;

  .assets-checkbox-block {
    display: flex;
    width: 15rem;
    height: 10.875rem;
    padding: 0.875rem 1rem;
    flex-direction: column;
    align-items: center;
    border-radius: 0.25rem;
    border: 0.063rem solid var(--#{$variable-prefix}bg-cloud-gray);
    cursor: pointer;
    position: relative;
    transition:
      all 0.3s ease,
      border-color 0.3s ease,
      box-shadow 0.3s ease;
    &:hover {
      border-color: var(--#{$variable-prefix}bg-soft-plum-gray);
      box-shadow: 0 0 0.375rem 0 rgba(0, 0, 0, 0.25);
      .assets-checkbox-img-holder {
        .icon {
          opacity: 1;
        }
        img {
          opacity: 0.75;
        }
      }
      &.assets-checkbox-preview {
        .common-dropdown-btn {
          color: var(--#{$variable-prefix}background-white);
        }
        .input-icon {
          border-color: var(--#{$variable-prefix}background-white);
        }
      }
    }
    &.active {
      border-color: var(--#{$variable-prefix}primary-color);
      box-shadow: 0 0 0.375rem 0 rgba(0, 0, 0, 0.25);
      .assets-checkbox-img-holder {
        .icon {
          opacity: 1;
        }
        img {
          opacity: 0.75;
        }
      }
    }

    .assets-checkbox-img-holder {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
      border-radius: 0.25rem;
      background-color: var(--#{$variable-prefix}bg-dark-navy);
      .icon {
        width: 2.125rem;
        height: 2.125rem;
        position: absolute;
        display: flex;
        padding: 0.5rem;
        justify-content: center;
        align-items: center;
        border-radius: 0.25rem;
        background: var(--#{$variable-prefix}primary-color);
        color: var(--#{$variable-prefix}white-color);
        opacity: 0;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .input-holder {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 0.625rem;
      position: relative;
      z-index: 1;
    }
    .input-icon {
      width: 1.125rem;
      height: 1.125rem;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--#{$variable-prefix}background-white);
      border: 0.063rem solid var(--#{$variable-prefix}bg-night-sky-gray);
      border-radius: 0.125rem;
      transition: background-color 0.3s ease;
      font-size: 0.875rem;
      svg {
        width: 0.625rem;
      }
    }
    input[type='checkbox'] {
      &:checked {
        + {
          .input-icon {
            color: var(--#{$variable-prefix}night-sky-gray);
          }
        }
      }
      display: none;
    }
    span {
      &:last-child {
        flex-grow: 1;
      }
    }

    .info-wrap {
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 1.875rem;
      .files-type {
        width: 4rem;
        height: 4rem;
      }
      .file-name {
        width: 100%;
        margin-bottom: 0.625rem;
        color: var(--#{$variable-prefix}soft-plum-gray);
        font-size: 0.7rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-align: center;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
    .common-dropdown-wrap {
      .common-dropdown-btn {
        width: auto;
        height: auto;
        padding: 0;
        border: 0;
      }
      .common-dropdown-menu {
        .dropdown-item {
          font-size: 0.75rem;
          svg {
            width: 0.75rem;
          }
        }
      }
    }
    &.assets-checkbox-preview {
      .common-dropdown-btn {
        color: var(--#{$variable-prefix}--bs-charcoal-gray);
      }
      .input-icon {
        background-color: transparent;
        border-color: var(--#{$variable-prefix}--bs-charcoal-gray);
        // border-color: var(--#{$variable-prefix}white-color);
      }
      input[type='checkbox'] {
        &:checked {
          + {
            .input-icon {
              color: var(--#{$variable-prefix}white-color);
            }
          }
        }
        display: none;
      }
    }
  }
  .file-name {
    margin-block: 8px 4px;
    strong {
      color: var(--bs-charcoal-gray);
      font-size: 1rem;
      font-style: normal;
      line-height: 120%;
      font-weight: 600;
      display: block;
      text-wrap: wrap;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .tag-name {
    display: flex;
    align-items: center;
    gap: 4px;
    flex-wrap: wrap;
    p {
      color: var(--#{$variable-prefix}soft-plum-gray);
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-align: left;
      border: 1px solid var(--#{$variable-prefix}bg-cloud-gray);
      padding: 2px 6px;
      border-radius: 30px;
    }
  }
}

.add-new-file-btn {
  display: flex;
  width: 15rem;
  height: 10.875rem;
  padding: 1.625rem 1.25rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  border-radius: 0.25rem;
  border: 0.063rem dashed var(--#{$variable-prefix}bg-cloud-gray);
  color: var(--#{$variable-prefix}soft-plum-gray);
  font-size: 0.813rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.004rem;
}