.data-not-found-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 8.25rem);
  max-height: calc(100vh - 8.25rem);
}

.data-not-found {
  display: flex;
  width: 15.125rem;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  .icon-wrap {
    color: var(--#{$variable-prefix}bg-cloud-gray);
  }
  .title-wrap {
    font-family: Inter;
    color: var(--#{$variable-prefix}dark-navy);
    text-align: center;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1rem;
  }
}
.assets-data-not-found{
  width: 100%;
  min-height: unset;
}