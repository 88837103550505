.chat-gpt-content {
  max-width: 52.188rem;
  margin: 0 auto;
  padding: 1.875rem 0;
  h2 {
    margin: 0 0 1rem;
    color: var(--#{$variable-prefix}charcoal-gray);
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5rem;
    letter-spacing: 0.006rem;
  }
  .chat-button-wrap {
    display: flex;
    gap: 1.875rem;
    margin: 0 0 3.75rem;
    button {
      padding: 0.375rem 1.25rem;
      font-size: 0.875rem;
      font-weight: 400;
    }
    .primary-btn {
      color: var(--#{$variable-prefix}charcoal-gray);
    }
  }
}

.chat-generate-wrap {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 0.625rem;
  align-self: stretch;
  .primary-btn {
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
  }
}

.characters-count {
  color: var(--#{$variable-prefix}soft-plum-gray);
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
  letter-spacing: 0.004rem;
}

.chat-gpt-textarea {
  margin: 0 0 1.875rem;
  width: 100%;
  textarea {
    min-height: calc(100vh - 26.875rem);
    height: 100%;
  }
}

.chat-wrap{
  &.active{
    border: solid 2px #6750a4
  }
}

.chat-title-wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  &.active{
    border: solid 2px #6750a4
  }
  &.slide{
    padding-left: 10px;
  }
  h4 {
    color: var(--#{$variable-prefix}charcoal-gray);
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: 0.005rem;
  }
  span {
    color: var(--#{$variable-prefix}soft-plum-gray);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: 0.004rem;
  }
  strong {
    font-weight: 700;
  }
}

.generate-progress-wrap {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.5rem;
  margin-top: 1rem;
  .generate-title {
    color: var(--#{$variable-prefix}charcoal-gray);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5rem;
    letter-spacing: 0.004rem;
  }
  .screen-size {
    color: var(--#{$variable-prefix}soft-plum-gray);
    text-align: left;
  }
  .loader-line {
    width: 100%;
    height: 0.375rem;
    &::before {
      height: 0.375rem;
    }
  }
}
