.circle-icon-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.625rem;
}

.circle-icon {
  display: flex;
  padding: 1.25rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 3.125rem;
  background-color: var(--#{$variable-prefix}background-white);
  border: 0.063rem solid var(--#{$variable-prefix}bg-cloud-gray);
}

.circle-icon-text {
  display: block;
  color: var(--#{$variable-prefix}dim-gray);
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.005rem;
  text-align: center;
}
