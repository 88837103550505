.media-wrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  align-self: stretch;
  margin-bottom: 1.25rem;
  h4 {
    margin: 0;
    color: var(--#{$variable-prefix}charcoal-gray);
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.005rem;
  }
}

.media-btn-wrap {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 1rem;
  align-self: stretch;
}

.media-btn {
  display: flex;
  width: 3.25rem;
  height: 3.25rem;
  padding: 1rem;
  justify-content: center;
  align-items: center;
  border-radius: 0.125rem;
  border: 0.063rem dashed var(--#{$variable-prefix}bg-cloud-gray);
}
