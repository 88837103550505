.mb-30 {
  margin-bottom: 1.875rem;
}

.primary-btn {
  display: flex;
  padding: 1rem 1.875rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  align-self: stretch;
  border-radius: 0.25rem;
  border: 0.063rem solid var(--#{$variable-prefix}primary-color);
  background: var(--#{$variable-prefix}background-white);
  color: var(--background-color-secondary, var(--#{$variable-prefix}primary-color));
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.005rem;
}

.login-page-wrapper {
  height: 100%;
}
.register-page-wrapper {
  height: unset;
}

.image-container {
  width: 100%;
  height: 100%;
  background: var(--#{$variable-prefix}bg-charcoal-gray);
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 767px) {
    padding: 1.25rem;
  }
  img {
    width: 100%;
    aspect-ratio: 2;
    object-fit: contain;
  }
}

.login-page {
  height: 100%;
  padding: 8.75rem 5.625rem 1.25rem;
  background-color: var(--#{$variable-prefix}background-white);
  @media (max-width: 991px) {
    padding: 3.75rem 2.5rem 2.5rem;
  }
}

.login-page-form {
  max-width: 30.25rem;
  @media (max-width: 767px) {
    margin: 0 auto;
  }
  h2 {
    margin-bottom: 0.625rem;
    color: var(--#{$variable-prefix}charcoal-black);
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.75rem;
    letter-spacing: 0.015rem;
  }
  h4 {
    margin-bottom: 1.875rem;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.005rem
  }

  .primary-btn {
    width: 100%;
    margin-bottom: 1.875rem;
  }

  .register-text {
    display: block;
    margin-bottom: 1.25rem;
    color: var(--#{$variable-prefix}night-sky-gray);
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.005rem;
    button {
      border: 0;
      background-color: transparent;
      color: var(--#{$variable-prefix}primary-color);
      font-size: 1rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.005rem;
    }
  }

  .login-policy-text {
    display: block;
    color: var(--#{$variable-prefix}night-sky-gray);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: 0.004rem;
    a {
      color: var(--#{$variable-prefix}primary-color);
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.5rem;
      letter-spacing: 0.004rem;
      text-decoration-line: underline;
    }
  }
}

.login-page-logo {
  max-width: 12.5rem;
  height: 3.75rem;
  margin-bottom: 1.875rem;
  svg {
    width: 100%;
  }
}
.country-code {
  width: 100%;
  .react-tel-input {
    width: unset !important;
    .form-control {
      background-color: unset;
      color: unset;
      border: 0.063rem solid var(--#{$variable-prefix}bg-soft-plum-gray) !important;
      height: 3.438rem !important;
      &:focus {
        padding: 1.156rem 0.875rem 1.156rem 3.75rem;
      }
    }
    .selected-flag {
      height: 3.438rem;
    }
  }
  .custom-form-floating {
    width: 100%;
  }
}
.react-tel-input .selected-flag:focus:before,
.react-tel-input .selected-flag.open:before {
  border-color: transparent !important;
  box-shadow: unset !important;
}
.custom-form-floating {
  .css-yk16xz-control {
    height: 3.438rem;
    border: 0.063rem solid var(--#{$variable-prefix}bg-soft-plum-gray);
    background-color: var(--#{$variable-prefix}background-white);
  }
  .css-1pahdxg-control {
    height: 3.438rem;
    border: 0.063rem solid var(--#{$variable-prefix}bg-soft-plum-gray);
    background-color: var(--#{$variable-prefix}background-white);
    outline: none;
    box-shadow: none;
  }
}
.custom-timezone {
  .css-1wa3eu0-placeholder {
    color: var(--#{$variable-prefix}soft-plum-gray);
    font-size: 1rem;
  }
  .css-1uccc91-singleValue {
    color: var(--#{$variable-prefix}charcoal-gray);
  }
}
.org-details {
  margin-bottom: 1.25rem;
  gap: 1.25rem;
  span {
    border: 0.031rem solid var(--#{$variable-prefix}bg-cloud-gray);
    height: 0.063rem;
    width: 50%;
  }
  p {
    color: var(--#{$variable-prefix}soft-plum-gray);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1rem; /* 114.286% */
    letter-spacing: 0.031rem;
    min-width: fit-content;
  }
}
.disabled-button {
  cursor: no-drop;
}
