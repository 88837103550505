.custom-upload-container {
  display: flex;
  padding: 1.875rem 1.25rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  border-radius: 0.25rem;
  border: 0.063rem dashed var(--#{$variable-prefix}bg-cloud-gray);
  cursor: pointer;

  .upload-icon {
    width: 1.5rem;
    height: 1.5rem;
  }

  .upload-text {
    color: var(--#{$variable-prefix}soft-plum-gray);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.004rem;
  }

  .upload-description {
    color: var(--#{$variable-prefix}soft-plum-gray);
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.004rem;
  }
}
