// Minibutton overrides
.btn-sm {
  padding: 0.125rem 0.625rem;
}

// Full-width button
.btn-block {
  display: block;
  width: 100%;
  text-align: center;
}

// main btn
.btn {
  outline: none;
  box-shadow: none;
  border: none;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-family: Inter !important;
  border-radius: 0.25rem;
  &:hover,
  &:active,
  &:focus {
    outline: none;
    box-shadow: none;
    // color: #11435a;
    // border: 0.063rem solid #11435a;
    // background: var(--#{$variable-prefix}background-white);
  }
}

.btn-primary {
  background: var(--#{$variable-prefix}cerulean-blue);
  box-shadow: 0 0.25rem 0.25rem rgba(70, 139, 232, 0.25);
  border: 0.031rem solid var(--#{$variable-prefix}cerulean-blue);
  color: var(--#{$variable-prefix}white-color);
  &:hover,
  &:active,
  &:focus {
    outline: none;
    box-shadow: none;
    border: 0.031rem solid var(--#{$variable-prefix}cerulean-blue);
    background: var(--#{$variable-prefix}cerulean-blue);
  }
}
.btn-outline {
  background: var(--#{$variable-prefix}background-white);
  border: 0.031rem solid var(--#{$variable-prefix}chill-gray);
  box-sizing: border-box;
  color: var(--#{$variable-prefix}light-gray-text);
  &:hover,
  &:active,
  &:focus {
    outline: none;
    box-shadow: none;
    background: var(--#{$variable-prefix}background-white);
    border: 0.031rem solid var(--#{$variable-prefix}chill-gray);
    color: var(--#{$variable-prefix}light-gray-text);
  }
}

.btn-outline-primary {
  background: transparent;
  border: 0.031rem solid $dark-grey-font;
  box-sizing: border-box;
  color: $dark-grey-font;
  padding: 0.563rem 0.75rem;
  &:hover,
  &:active,
  &:focus {
    outline: none;
    box-shadow: none;
    background: transparent;
    border: 0.031rem solid $dark-grey-font;
    color: $dark-grey-font;
  }
}

.editor-btn {
  border: 0.031rem solid var(--#{$variable-prefix}chill-gray);
  box-sizing: border-box;
  background: var(--#{$variable-prefix}background-white);
  padding: 0.313rem;
  min-height: 1.5rem;
  max-height: 1.5rem;
  &.active,
  &:hover {
    background: var(--#{$variable-prefix}light-platinum);
  }
}
.br-2 {
  border-radius: 0.125rem;
}
.Decoration-btn .editor-btn {
  min-width: 1.5rem;
  min-height: 1.5rem;
  max-height: 1.5rem;
}
.render-btn {
  height: 2.5rem;
}
.cancel-button {
  position: absolute;
  top: 50% !important;
  left: 9.375rem !important;
  z-index: 100;
  transform: translateY(-50%);
  width: fit-content;
}

// New design button
.outline-primary-btn {
  display: flex;
  padding: 0.5rem 1rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  border-radius: 0.25rem;
  border: 0.063rem solid var(--#{$variable-prefix}primary-color);
  background-color: transparent !important;
  color: var(--#{$variable-prefix}primary-color);
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5rem;
  letter-spacing: 0.004rem;
}

.default-add-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  color: var(--#{$variable-prefix}charcoal-black);
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.005rem;
  &:hover {
    color: var(--#{$variable-prefix}charcoal-black);
  }
}

.btn-default {
  display: flex;
  padding: 0.5rem 1rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  color: var(--#{$variable-prefix}charcoal-gray);
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
  letter-spacing: 0.004rem;
  border: 0.063rem solid transparent;
  &:hover {
    border-color: var(--#{$variable-prefix}primary-color);
    color: var(--#{$variable-prefix}primary-color);
  }
}

.bg-btn-primary {
  background: var(--#{$variable-prefix}primary-color) !important;
  border: 0.063rem solid var(--#{$variable-prefix}primary-color);
  color: var(--#{$variable-prefix}white-color);
  padding: 0.625rem 2.5rem;
  text-align: center;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1rem;
  letter-spacing: 0.025rem;
  &:hover,
  &:active,
  &:focus {
    outline: none;
    box-shadow: none;
    border: 0.063rem solid var(--#{$variable-prefix}primary-color);
    background: var(--#{$variable-prefix}primary-color);
  }
}

.bg-primary-btn {
  display: flex;
  padding: 1rem 1.875rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  color: var(--#{$variable-prefix}white-color);
  border: 0;
  border-radius: 0.25rem;
  background: var(--#{$variable-prefix}primary-color);
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.005rem;
}

.create-new-btn {
  display: flex;
  width: 100%;
  min-height: 23.063rem;
  height: 100%;
  padding: 1.5rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  border-radius: 0.25rem;
  border: 0.094rem dashed var(--#{$variable-prefix}bg-cloud-gray);
  background-color: var(--#{$variable-prefix}background-white);
  transition: all 0.2s ease-in-out;
  &:hover,
  &.active {
    border-color: var(--#{$variable-prefix}primary-color);
    .icon-wrap {
      background: var(--#{$variable-prefix}primary-color);
    }
    .create-title {
      color: var(--#{$variable-prefix}primary-color);
    }
  }
  .icon-wrap {
    display: flex;
    padding: 0.625rem;
    justify-content: center;
    align-items: center;
    border-radius: 6.25rem;
    background: var(--#{$variable-prefix}bg-cloud-gray);
    color: var(--#{$variable-prefix}white-color);
    svg {
      width: 1.125rem;
      height: 1.125rem;
    }
  }
  .create-title {
    color: var(--#{$variable-prefix}soft-plum-gray);
    text-align: center;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.031rem;
  }
}

// Full width button
.fw-btn {
  .btn {
    width: 100%;
    font-size: 1rem;
    padding: 1rem 1.875rem;
  }
}

.skip-btn-wrap {
  display: flex;
  justify-content: flex-end;
  .btn {
    color: var(--#{$variable-prefix}primary-color);
  }
}

.small-btn {
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
}

.upload-btn {
  display: flex;
  padding: 0.625rem 0.791rem 0.625rem 0.813rem;
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem;
  border: 0.063rem solid var(--#{$variable-prefix}primary-color);
  background: var(--#{$variable-prefix}bg-light-aluminum);
  color: var(--#{$variable-prefix}primary-color);
  text-align: center;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1rem;
  letter-spacing: 0.025rem;
  cursor: pointer;
  input {
    display: none;
  }
}
