.inner-sidebar {
  position: fixed;
  top: 0;
  left: 4.625rem;
  width: 20.813rem;
  height: 100%;
  background-color: var(--#{$variable-prefix}bg-dark-gray);
  color: var(--#{$variable-prefix}white-color);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-x: hidden;
  overflow-y: auto;
  transition: width 0.4s ease-in;
  z-index: 10;
}
.hide-sidebar{
  width: 0;
  .inner-sidebar-header{
    width: 0;
  }
}
.show-hide-sidebar{
  position: absolute;
  top: 10px;
  left: -10px;
  background-color: #eef2f7;
  color: #bdbcb8;
  z-index: 10;
  width: 2rem;
  height: 2rem;
  box-shadow: var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 20px;
}

.inner-sidebar-holder {
  overflow-x: hidden;
  overflow-y: auto;
}

.inner-sidebar-header {
  position: fixed;
  width: 20.813rem;
  background-color: var(--#{$variable-prefix}bg-dark-navy);
  border-bottom: 0.063rem solid var(--#{$variable-prefix}bg-dark-gray);
  color: var(--#{$variable-prefix}white-color);
  display: flex;
  padding: 0.75rem 1rem;
  align-items: flex-start;
  gap: 0.938rem;
  z-index: 1;
  &:hover {
    color: var(--#{$variable-prefix}white-color);
  }
  .icon-wrap {
    display: flex;
    padding: 0.5rem;
    justify-content: center;
    align-items: center;
    border-radius: 0.25rem;
    background: var(--#{$variable-prefix}primary-color);
  }
  .info-wrap {
    h4 {
      margin: 0 0 0.25rem;
      color: var(--#{$variable-prefix}white-color);
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.005rem;
    }
    span {
      color: var(--#{$variable-prefix}primary-color);
      font-size: 1rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.005rem;
    }
  }
}

.inner-sidebar-footer {
  display: flex;
  padding: 1.875rem 1.25rem;
  flex-direction: column;
  align-items: center;
  gap: 1.25rem;
  align-self: stretch;
}
