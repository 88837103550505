.create-new-project-modal {
  .modal-dialog {
    max-width: 65.313rem;
  }
  .modal-body {
    width: 100%;
    padding: 3.125rem;
  }
  .modal-content {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 2.5rem;
    border-radius: 0.25rem;
    background: var(--#{$variable-prefix}background-white);
    box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.25);
  }
}

.create-new-project-info {
  h4 {
    margin: 0 0 1rem;
    color: var(--#{$variable-prefix}charcoal-gray);
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.011rem;
  }
  p {
    margin: 0 0 1.875rem;
    color: var(--#{$variable-prefix}soft-plum-gray);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: 0.004rem;
  }
}

.create-new-category-holder {
  display: flex;
  justify-content: space-between;
  gap: 0.625rem;
  .custom-select-dropdown,
  .custom-form-floating {
    flex: 1;
  }
  .btn,
  .primary-btn {
    padding: 1rem 1.25rem;
    font-size: 0.875rem;
  }
  .separater {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.25rem;
    align-self: stretch;
    width: 1.188rem;
    color: var(--#{$variable-prefix}soft-plum-gray);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1rem;
    letter-spacing: 0.031rem;
    position: relative;
    text-transform: uppercase;
    &:before,
    &:after {
      content: '';
      position: absolute;
      width: 0.063rem;
      height: 0.958rem;
      background: var(--#{$variable-prefix}bg-cloud-gray);
    }
    &:before {
      top: 0;
    }
    &:after {
      bottom: 0;
    }
  }
}

.virtual-img-wrap {
  padding: 1.875rem 0 0.625rem 0.625rem;
}
