.common-card {
  display: flex;
  padding: 0.625rem;
  flex-direction: column;
  gap: 0.25rem;
  align-self: stretch;
  border-radius: 0.25rem;
  background: var(--#{$variable-prefix}bg-light-aluminum);
  border: 0.063rem solid transparent;
  margin-bottom: 0.625rem;
  &:last-child {
    margin-bottom: 0;
  }
  cursor: pointer;
  &:hover,
  &.active {
    border-color: var(--#{$variable-prefix}primary-color);
  }
  .card-header {
    display: flex;
    justify-content: space-between;
    gap: 0.5rem;
    background-color: transparent;
    border: 0;
    padding: 0;
    .card-title {
      color: var(--#{$variable-prefix}charcoal-gray);
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 700;
      line-height: 1.5rem;
      letter-spacing: 0.004rem;
    }
  }
  .card-body {
    padding: 0;
    .card-text {
      color: var(--#{$variable-prefix}charcoal-gray);
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.5rem;
      letter-spacing: 0.004rem;
    }
  }
}
