.common-dropdown-wrap {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .common-dropdown-menu {
    max-height: 15.438rem;
    overflow-y: auto;
    overflow-x: hidden;
  }
  @media (max-width: 991px) {
    display: block;
    margin-top: 1.25rem;
  }
}

.common-dropdown-menu {
  min-width: 14.125rem;
  border: 0.063rem solid var(--#{$variable-prefix}bg-cloud-gray);
  background: var(--#{$variable-prefix}background-white);
  padding: 0;
  border-radius: 0;
  @media (max-width: 991px) {
    position: relative;
  }
  &.dropdown-menu {
    margin-top: 0.725rem;
  }
  .dropdown-item {
    display: flex;
    padding: 1rem;
    align-items: center;
    gap: 0.625rem;
    color: var(--#{$variable-prefix}soft-plum-gray);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.004rem;
    border-left: 0.125rem solid transparent;
    &:hover {
      background-color: var(--#{$variable-prefix}bg-light-aluminum);
      border-color: var(--#{$variable-prefix}primary-color);
    }
  }
}

.profile-dropdown-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  z-index: 10001;
  cursor: pointer;
  @media (max-width: 991px) {
    justify-content: end;
  }
  .user-info {
    h4 {
      color: var(--#{$variable-prefix}charcoal-black);
      font-size: 1rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    span {
      color: var(--#{$variable-prefix}primary-color);
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-align: right;
    }
  }
}

.script-dropdown-menu {
  min-width: 7.5rem;
  border-radius: 0.25rem;
  justify-content: center;
  .dropdown-item {
    padding: 0.75rem 1rem;
  }
}

.common-dropdown-btn {
  display: flex;
  width: 1.5rem;
  height: 1.5rem;
  padding: 0.25rem;
  justify-content: center;
  align-items: center;
  border-radius: 1.875rem;
  border: 0.063rem solid var(--#{$variable-prefix}bg-cloud-gray);
  color: var(--#{$variable-prefix}soft-plum-gray);
}
