.brand-setting-modal {
  .modal-dialog {
    max-width: 40.625rem;
  }

  .modal-body {
    padding: 0;
  }

  .modal-footer {
    padding: 0.75rem 0 0;
    border: 0;
  }

  .modal-content {
    padding: 2.5rem;
    gap: 1.25rem;
    border-radius: 0.25rem;
    background: var(--#{$variable-prefix}background-white);
    box-shadow: 0 0 0.375rem 0 rgba(0, 0, 0, 0.25);
  }
  p {
    color: var(--#{$variable-prefix}soft-plum-gray);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.004rem;
    margin-bottom: 1.25rem;
  }
  h2 {
    margin-bottom: 0.625rem;
    color: var(--#{$variable-prefix}charcoal-gray);
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.011rem;
  }
  h4 {
    margin-bottom: 1.2rem;
    color: var(--#{$variable-prefix}charcoal-gray);
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.005rem;
  }

  .border-line-bottom {
    border-bottom: 0.063rem solid var(--#{$variable-prefix}bg-cloud-gray);
    margin-bottom: 1.25rem;
    padding-bottom: 1.25rem;
  }

  .border-line-top {
    border-top: 0.063rem solid var(--#{$variable-prefix}bg-cloud-gray);
    margin-top: 1.25rem;
    padding-top: 1.25rem;
  }
  
  .custom-form-floating {
    margin-bottom: 0;
  }

  .custom-color-input {
    max-width: 12.25rem;
  }
}

.image-file-updated {
  width: 100%;
  height: 7.5rem;
  padding: 0.625rem;
  border-radius: 0.25rem;
  border: 0.063rem dashed var(--#{$variable-prefix}bg-cloud-gray);
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}