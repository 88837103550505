.progress-wrap {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 0.6rem;
  .progress {
    display: flex;
    flex: 1;
    height: 0.375rem;
    overflow: hidden;
    background-color: var(--#{$variable-prefix}bg-cloud-gray);
    border-radius: 0.375rem;
  }
  .progress-value {
    width: 2.063rem;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.005rem;
    text-align: right;
  }
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  background-color: var(--#{$variable-prefix}slate-blue);
  transition: width 0.6s ease;
  border-radius: 0.375rem;
  &.bg-primary {
    background-color: var(--#{$variable-prefix}primary-color) !important;
  }

  &.bg-success {
    background-color: var(--#{$variable-prefix}secondary-color) !important;
  }

  &.bg-warning {
    background-color: var(--#{$variable-prefix}bg-vivid-orange) !important;
  }

  &.bg-default {
    background-color: var(--#{$variable-prefix}bg-soft-plum-gray) !important;
  }
}

.reverse-progressbar {
  flex-direction: row-reverse;
  .progress {
    height: 0.25rem;
    border-radius: 0;
  }
  .progress-bar {
    border-radius: 0;
  }
  .progress-value {
    font-size: 0.813rem;
  }
}

.loading-progressbar {
  display: inline-flex;
  align-items: center;
  gap: 1.25rem;
  width: 100%;
  .progress-wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.625rem;
    .progress {
      flex: auto;
      width: 100%;
      height: 0.25rem;
      border-radius: 0;
    }
    .progress-bar {
      border-radius: 0;
    }
  }
  .progress-info {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 0.625rem;
    h4 {
      color: var(--#{$variable-prefix}charcoal-gray);
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      flex: 1;
    }
    .progress-value {
      width: auto;
      color: var(--#{$variable-prefix}charcoal-gray);
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
    }
  }
  .close-btn {
    background-color: transparent;
    border: 0;
    padding: 0;
    cursor: pointer;
    svg {
      path {
        fill: var(--#{$variable-prefix}soft-plum-gray);
      }
    }
  }
}
