.custom-checkbox-wrap {
    position: relative;
    position: relative;
    width: 1.125rem;
    margin: 0 auto;
    input[type='checkbox'] {
      position: absolute;
      opacity: 0;
      width: 0;
      height: 0;
    }
  
    .input-icon {
      width: 1.125rem;
      height: 1.125rem;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--#{$variable-prefix}background-white);
      border: 0.063rem solid var(--#{$variable-prefix}bg-night-sky-gray);
      border-radius: 0.125rem;
      transition: background-color 0.3s ease;
      font-size: 0.875rem;
      cursor: pointer;
      svg {
        opacity: 0;
        width: 0.625rem;
      }
      &.checked {
        background-color: var(--#{$variable-prefix}secondary-color);
        border-color: var(--#{$variable-prefix}secondary-color);
        color: var(--#{$variable-prefix}white-color);
        svg {
          opacity: 1;
        }
      }
    }
  }