.custom-tab {
  border: 0;
  margin-bottom: 1.25rem;
  border-bottom: 0.063rem solid var(--#{$variable-prefix}bg-cloud-gray);
  .nav-link {
    display: flex;
    padding: 0.625rem 1rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    border: none;
    color: var(--#{$variable-prefix}charcoal-gray);
    text-align: center;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.005rem;
    @media (max-width: 1536px) {
      padding: 0.625rem 0.563rem;
      font-size: 0.875rem;
    }
    &.active {
      color: var(--#{$variable-prefix}charcoal-black);
      border-bottom: 0.125rem solid var(--#{$variable-prefix}primary-color);
      font-weight: 700;
      .tabs-icon {
        background-color: var(--#{$variable-prefix}primary-color);
        color: var(--#{$variable-prefix}white-color);
      }
    }
  }

  .tabs-icon {
    display: flex;
    width: 1.75rem;
    height: 1.75rem;
    padding: 0.375rem;
    justify-content: center;
    align-items: center;
    border-radius: 0.125rem;
    background-color: var(--#{$variable-prefix}bg-light-aluminum);
  }
}
