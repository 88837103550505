.screen-wrapper {
  height: calc(100vh - 4.163rem);
}

.screen-holder {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.screen-render-holder {
  min-height: calc(100% - 15.438rem);
  max-height: calc(100% - 15.438rem);
  overflow-y: auto;
  overflow-x: hidden;
  @media (max-width: 1600px) {
    min-height: calc(100% - 12.813rem);
    max-height: calc(100% - 12.813rem);
  }
  @extend .screen-holder;
  .video-preview-wrap {
    width: 100%;
    flex: 1;
  }
  .video-preview-wrap {
    .react-player {
      // @media (max-width: 1600px) {
      //   max-height: 24.188rem;
      //   min-height: 24.188rem;
      // }
      aspect-ratio: 16 / 9;
    }
  }
}

.screen-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.625rem 2.5rem;
  gap: 1.25rem;
  align-self: stretch;
  border-bottom: 0.063rem solid var(--#{$variable-prefix}bg-cloud-gray);
  margin-right: -0.75rem;
  .default-add-btn {
    font-size: 0.875rem;
    color: var(--#{$variable-prefix}charcoal-gray);
  }
}

.screen-body {
  display: flex;
  padding: 1.875rem 5rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.313rem;
  align-self: stretch;
  flex: 1;
}

.screen-footer {
  display: flex;
  padding: 1.25rem 7.5rem 1.25rem 5rem;
  align-items: flex-start;
  gap: 0.75rem;
  align-self: stretch;
  border-top: 0.063rem solid var(--#{$variable-prefix}bg-cloud-gray);
  background: var(--#{$variable-prefix}background-white);
  // overflow: hidden;
  margin-right: -0.75rem;
}

.add-new-section-wrap {
  width: 8.375rem;
  .add-new-screen-btn {
    width: 8.375rem;
    height: 5.625rem;
    padding: 0.25rem;
  }
  .screen-thumbnail-items-wrap {
    > span {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      display: block;
    }
  }
}

.video-preview-content {
  display: flex;
  width: 100%;
  // min-height: calc(100vh - 25.063rem);
  padding: 0.875rem;
  justify-content: center;
  align-items: center;
  border: 0.063rem solid var(--#{$variable-prefix}bg-cloud-gray);
  background: var(--#{$variable-prefix}background-white);
  position: relative;
  aspect-ratio: 16 / 9;
  .video-wrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0.875rem;
    img,
    video,
    iframe {
      width: 100%;
      height: 100%;
    }
  }
}

.screen-title-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 0 0.625rem 0;
  gap: 0.375rem;
}

.screen-sidebar {
  display: flex;
  border-left: 0.063rem solid var(--#{$variable-prefix}bg-cloud-gray);
  background: var(--#{$variable-prefix}background-white);
  padding: 1.25rem 1.875rem 1.875rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.25rem;
  @media (max-width: 1600px) {
    padding: 1.25rem 1.688rem 1.875rem;
  }
}

.screen-sidebar-header {
  width: 100%;
  border-bottom: 0.063rem solid var(--font-color-secondary, var(--#{$variable-prefix}bg-cloud-gray));
  padding-bottom: 1.3rem;
  z-index: 0;
}

.screen-sidebar-body,
.screen-sidebar-footer {
  width: 100%;
}

.screen-sidebar-body {
  // min-height: calc(100vh - 16.125rem);
  max-height: calc(100vh - 16.125rem);
  overflow-y: auto;
  overflow-x: hidden;
}

.add-new-screen-btn {
  display: flex;
  padding: 1.625rem 1.25rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  border-radius: 0.25rem;
  border: 0.063rem dashed var(--#{$variable-prefix}bg-cloud-gray);
  background: var(--#{$variable-prefix}bg-pale-silver);
  color: var(--#{$variable-prefix}primary-color);
  font-size: 0.813rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.004rem;
}

.screen-thumbnail-items {
  flex: 0 0 auto;
  width: 9.375rem;
  height: 5.625rem;
  border: 0.25rem solid var(--#{$variable-prefix}background-white);
  box-shadow: 0 0 0.063rem 0 rgba(0, 0, 0, 0.8);
  background-color: var(--#{$variable-prefix}bg-cloud-gray);
  scroll-snap-align: start;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  position: relative;
  .close-btn {
    z-index: 10;
    visibility: hidden;
    position: absolute;
    top: 0.25rem;
    right: 0.25rem;
    background-color: var(--#{$variable-prefix}background-white);
    border-radius: 50%;
    width: 1rem;
    height: 1rem;
    padding: 0.25rem;
    margin: 0;
    cursor: pointer;
  }
  &:hover {
    .close-btn {
      visibility: visible;
    }
  }
  &.active {
    .check-video {
      visibility: visible;
    }
  }
  &.active,
  &:hover {
    border-color: var(--#{$variable-prefix}primary-color);
    ~ span {
      font-weight: 600;
    }
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.screen-thumbnail-items-wrap {
  span {
    margin-top: 0.625rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--#{$variable-prefix}soft-plum-gray);
    font-size: 0.813rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.004rem;
  }

  .screen-text {
    bottom: 0;
    margin: 0;
    width: 100%;
    padding: 0.25rem;
    color: var(--#{$variable-prefix}white-color);
    font-size: 0.75rem;
    font-weight: 700;
    position: absolute;
    background: var(--#{$variable-prefix}bg-soft-plum-gray);
    justify-content: start;
  }
  .crashed-text{
    position: absolute;
    background: var(--#{$variable-prefix}bg-soft-plum-gray);
    justify-content: center;
    bottom: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    padding: 0.25rem;
    color: var(--#{$variable-prefix}white-color);
    font-size: 0.75rem;
    font-weight: 700;
  }

  .carousel-icon-wrap {
    opacity: 0;
    width: 100%;
    height: 100%;
    display: flex;
    position: absolute;
    align-items: center;
    flex-direction: row;
    justify-content: space-around;

    span {
      margin: 0;
      width: 100%;
      height: 100%;
      color: var(--#{$variable-prefix}white-color);
    }

    .image-view:hover,
    .image-edit:hover {
      background: rgba(0, 0, 0, 0.3);
    }
  }

  .carousel-icon-wrap:hover {
    opacity: 1;
    span {
      background: rgba(70, 139, 232, 0.146);
    }
  }
}

.slick-slider {
  width: 100%;
  .slick-arrow {
    display: none;
  }
  .carousel-block {
    margin: 0.125rem 0.75rem 0.125rem 0;
  }
}

.screen-sidebar-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0.625rem;
}

.calculate-input-wrapper {
  margin-top: 2rem;
  margin-bottom: 0.938rem;
}

.screen-header-back {
  span {
    margin: 0 0 0.625rem;
    display: flex;
    align-items: center;
    gap: 0.625rem;
    color: var(--#{$variable-prefix}charcoal-gray);
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.005rem;
  }
  p {
    margin: 0;
    color: var(--#{$variable-prefix}soft-plum-gray);
    font-size: 0.813rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.004rem;
  }
}

.screen-audio-wrap {
  min-height: calc(100vh - 18.75rem);
  max-height: calc(100vh - 18.75rem);
}

.screen-btn-wrap {
  display: flex;
  align-items: center;
  gap: 1.25rem;
  margin-top: 1.875rem;
}

.screen-action-btn {
  display: flex;
  padding: 1.25rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  flex: 1 0 0;
  border: 0;
  background-color: transparent;
  border-radius: 0.25rem;
  border: 0.063rem solid var(--#{$variable-prefix}bg-cloud-gray);
  color: var(--#{$variable-prefix}soft-plum-gray);
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.004rem;
  cursor: pointer;
}

.button-wrap {
  display: flex;
  align-items: center;
  gap: 1.25rem;
}

.screen-size {
  color: var(--#{$variable-prefix}charcoal-gray);
  text-align: right;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.004rem;
}

.screen-render-wrap {
  margin: 0 auto;
  max-width: 58.375rem;
  min-height: 36.438rem;
  gap: 0.625rem;
  padding: 2.5rem 1.25rem;
}

.go-back-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  color: var(--#{$variable-prefix}charcoal-gray);
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
  letter-spacing: 0.004rem;
}

.triiger-btn {
  border-radius: 0.25rem;
  border: 0.063rem dashed var(--#{$variable-prefix}bg-soft-plum-gray);
  background: var(--#{$variable-prefix}background-white);
  display: flex;
  width: 6.313rem;
  height: 4.438rem;
  padding: 0.625rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  @media (max-width: 1600px) {
    width: 6.125rem;
  }
  .upload-icon {
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--#{$variable-prefix}bg-light-aluminum);
    border-radius: 50%;
  }
}

.add-media-assests {
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 0.625rem;
  flex-shrink: 0;
  align-self: stretch;
  flex-wrap: wrap;
  margin-top: 1.25rem;
  padding: 0.063rem;
  .screen-thumbnail-items {
    width: 6.313rem;
    height: 4.438rem;
    @media (max-width: 1600px) {
      width: 6.125rem;
    }
  }
}

.theme-templates-wrap {
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 1.25rem;
  flex-wrap: wrap;
  padding: 0.063rem;
  .screen-thumbnail-items {
    &.active {
      .check-video {
        visibility: visible;
      }
    }
    .check-video {
      visibility: hidden;
      position: absolute;
      top: -0.188rem;
      right: 0;
    }
  }
  .triiger-btn {
    width: 46.8%;
    height: 7.5rem;
  }
  .carousel-block {
    width: 46.8%;
  }
  .screen-thumbnail-items {
    width: 100%;
    height: 7.5rem;
  }
}

.screen-generate-wrap {
  padding: 1.875rem 3.125rem;
}

.recommended-templates-wrap {
  .slick-slider {
    margin: 0 -0.5rem;
    .screen-thumbnail-items {
      width: 11.875rem;
      height: 6.25rem;
    }
  }
  .check-video {
    visibility: hidden;
    position: absolute;
    top: -0.188rem;
    right: 0;
  }
  .template-info {
    margin-bottom: 0.625rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1.25rem;
    h4 {
      color: var(--#{$variable-prefix}charcoal-gray);
      font-size: 1rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.005rem;
      flex: 1;
    }
    span {
      display: flex;
      padding: 0.5rem 1rem;
      justify-content: center;
      align-items: center;
      gap: 0.625rem;
      color: var(--#{$variable-prefix}charcoal-gray);
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.5rem;
      letter-spacing: 0.004rem;
    }
  }
}

.slider-main-wrapper {
  // max-width: 48.95rem;
  width: 90%;
  // @media (max-width: 1600px) {
  //   max-width: 33rem;
  // }
  .slick-slider {
    .slick-arrow {
      display: block !important;
      border-radius: 50%;
      position: absolute;
      z-index: 1;
      top: 2.813rem;
    }
    .slick-prev {
      left: 0.938rem;
    }
    .slick-next {
      right: 0.25rem;
    }
    .slick-next:before,
    .slick-prev:before {
      color: var(--#{$variable-prefix}primary-color);
    }
  }
  .carousel-block {
    width: 9.375rem;
  }
  .screen-thumbnail-items-wrap {
    > span {
      padding: 0 0.25rem;
      display: block;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      text-align: center;
    }
  }
}
.render-slide-wrapper{
  max-width: unset;
  width: 100%;
  @media (max-width: 1600px) {
    max-width: unset;
    width: 100%;
  }
}

@media (max-width: 1600px) {
  .responsive-layout {
    .row {
      gap: 1.25rem;
    }
    [class*='col-'] {
      width: 100%;
    }
  }
  .calculate-input-wrapper {
    [class*='col-'] {
      width: 100%;
      padding: 0 0.75rem;
    }
  }
  .screen-render-wrap {
    .video-preview-content {
      min-height: calc(100vh - 18.75rem);
    }
  }
}

.red-border {
  border-left: red solid 2px !important;
}
