.tag-input-content {
  border-bottom: 0.063rem solid var(--#{$variable-prefix}bg-cloud-gray);
  margin-bottom: 1.25rem;
  h4 {
    margin: 0 0 1rem;
    color: var(--#{$variable-prefix}charcoal-gray);
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.005rem
  }
}

.tag-content-btn {
  width: 95%;
  height: auto;
  padding: 0.938rem;
  span {
    color: var(--#{$variable-prefix}soft-plum-gray);
    text-align: center;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.031rem;
  }
}

.tag-input-holder {
  display: flex;
  align-items: center;
  grid-gap: 0.625rem;
  gap: 0.625rem;
  margin: 1.563rem 0 1.25rem;
}

.tag-input-wrapper {
  flex: 1;
  .custom-form-textarea {
    margin-bottom: 1.25rem;
    textarea {
      height: 6.063rem;
    }
  }
}

.tag-input-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 0.25rem;
  border: 0.063rem solid var(--#{$variable-prefix}bg-soft-plum-gray);
  background: var(--#{$variable-prefix}background-white);
  padding: 0.625rem 0.875rem;
  flex: 1;
  &.tag-focused {
    border-color: var(--#{$variable-prefix}primary-color);
  }

  .floating-tag-label {
    position: relative;
    top: 0;
    left: 0;
    padding: 0.25rem;
    color: var(--#{$variable-prefix}soft-plum-gray);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1rem;
    letter-spacing: 0.031rem;
    pointer-events: none;
    transition:
      transform 0.2s ease,
      font-size 0.2s ease;
  }
}

.tag-input {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 0.125rem 0;
  border-radius: 0.313rem;
  align-items: center;
  gap: 0.5rem;
  flex-grow: 1;
  position: relative;

  .tag {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.375rem;
    background-color: var(--#{$variable-prefix}bg-light-aluminum);
    padding: 0.5rem;
    color: var(--#{$variable-prefix}charcoal-gray);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1rem;
    letter-spacing: 0.031rem;

    .tag-remove {
      color: var(--#{$variable-prefix}charcoal-gray);
      margin-left: 0.313rem;
      cursor: pointer;
      svg {
        path {
          fill: var(--#{$variable-prefix}charcoal-gray);
        }
      }
    }
  }

  input {
    border: none;
    outline: none;
    padding: 0 0.25rem;
    flex-grow: 1;
    color: var(--#{$variable-prefix}soft-plum-gray);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1rem;
    letter-spacing: 0.031rem;
  }
}

.tag-focused .floating-tag-label {
  transform: translateY(-1.25rem);
  font-size: 0.8rem;
  left: 0;
  border-radius: 1.875rem;
  background: var(--#{$variable-prefix}bg-light-aluminum);
  padding: 0.25rem 0.625rem;
}
