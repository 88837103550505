.radio-button-wrap {
  display: flex;
  align-items: flex-start;
  gap: 1.875rem;
}

.radio-label {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  flex: 1 0 0;
  padding: 0.625rem 1rem;
  border-radius: 0.25rem;
  border: 0.063rem solid var(--#{$variable-prefix}bg-cloud-gray);
  cursor: pointer;

  input[type='radio'] {
    display: none;
  }

  .radio-circle {
    display: flex;
    width: 1rem;
    height: 1rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    flex-shrink: 0;
    border: 0.094rem solid var(--#{$variable-prefix}bg-cloud-gray);
    border-radius: 50%;
    background-color: var(--#{$variable-prefix}background-white);
    transition:
      border-color 0.3s,
      background-color 0.3s;
  }

  &.checked {
    .radio-circle {
      border-color: var(--#{$variable-prefix}primary-color);
    }
    .radio-inner {
      width: 0.625rem;
      height: 0.625rem;
      background-color: var(--#{$variable-prefix}primary-color);
      border-radius: 50%;
      transition: background-color 0.3s;
    }
  }
  .radio-info {
    flex: 1;
    h4 {
      color: var(--#{$variable-prefix}charcoal-gray);
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.004rem;
    }
    span {
      color: var(--#{$variable-prefix}soft-plum-gray);
      font-size: 0.813rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.004rem;
    }
  }
}
