.loader-line {
  width: 12.5rem;
  height: 0.188rem;
  position: relative;
  overflow: hidden;
  background-color: var(--#{$variable-prefix}bg-cloud-gray);
  margin: 0.625rem auto;
  -webkit-border-radius: 1.25rem;
  -moz-border-radius: 1.25rem;
  border-radius: 1.25rem;
  &:before {
    content: '';
    position: absolute;
    left: -50%;
    height: 0.188rem;
    width: 40%;
    background-color: var(--#{$variable-prefix}primary-color);
    -webkit-animation: lineAnim 1s linear infinite;
    -moz-animation: lineAnim 1s linear infinite;
    animation: lineAnim 1s linear infinite;
    -webkit-border-radius: 1.25rem;
    -moz-border-radius: 1.25rem;
    border-radius: 1.25rem;
  }
}

@keyframes lineAnim {
  0% {
    left: -40%;
  }
  50% {
    left: 20%;
    width: 80%;
  }
  100% {
    left: 100%;
    width: 100%;
  }
}
