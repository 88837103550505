.custom-switch-label {
  display: inline-block;
  cursor: pointer;

  input {
    display: none;
  }
}

.custom-switch-slider {
  position: relative;
  display: inline-block;
  width: 3.375rem;
  height: 1.75rem;
  background-color: var(--#{$variable-prefix}bg-soft-plum-gray);
  border-radius: 1.25rem;
  transition: background-color 0.3s ease;

  .circle {
    position: absolute;
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 50%;
    background-color: var(--#{$variable-prefix}background-white);
    top: 50%;
    left: 0.375rem;
    transform: translateY(-50%);
    transition: transform 0.3s ease;
  }

  .label-text {
    position: absolute;
    display: flex;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    padding: 0 0.375rem;
    justify-content: space-between;
    transition: opacity 0.3s ease;
    .on {
      opacity: 0;
      transition: opacity 0.5s ease;
    }

    .on,
    .off {
      color: var(--#{$variable-prefix}white-color);
      font-size: 0.625rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  &.checked {
    background-color: var(--#{$variable-prefix}primary-color);
    .circle {
      // left: auto;
      // right: 0.375rem;
      transform: translate(1.5rem, -50%);
    }
    .on {
      opacity: 1;
      transition: opacity 0s ease;
    }
    .off {
      opacity: 0;
      transition: opacity 0.5s ease;
    }
  }
}
