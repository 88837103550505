.header-navbar {
  background-color: var(--#{$variable-prefix}background-white);
  border-bottom: 0.063rem solid var(--#{$variable-prefix}bg-cloud-gray);
  padding: 0.75rem 1.25rem;
  height: 4.125rem;
}

.navbar-toggler-wrap {
  @media (max-width: 991px) {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
}

.user-info-holder {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @media (max-width: 991px) {
    margin-top: 1.25rem;
    display: block;
    .default-add-btn {
      width: 100%;
      display: inline-flex;
      justify-content: flex-start;
    }
  }
}

.circle-wrap {
  aspect-ratio: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.25rem;
  height: 2.25rem;
  overflow: hidden;
  color: var(--#{$variable-prefix}white-color);
  border-radius: 6.25rem;
  background-color: var(--#{$variable-prefix}primary-color);
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  @include border-radius($border-radius-full);
  img {
    width: $w-100;
    aspect-ratio: 1;
    object-fit: cover;
  }
  svg {
    width: $w-100;
    aspect-ratio: 1;
  }
}

// create project
.secondary-header {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
  border-bottom: 0.063rem solid var(--#{$variable-prefix}gentle-sky);
  padding: 0.75rem 1.25rem;
  .custom-search-form {
    margin: 0;
    flex: 1;
  }
}

.secondary-sticky-header {
  position: sticky;
  top: 4.125rem;
  z-index: 8;
  background-color: var(--#{$variable-prefix}background-white);
  transition: all 0.3s ease-in-out;
}

.create-project-wrapper {
  padding: 2.5rem 2.5rem 0;
  min-height: calc(100vh - 12.313rem);
  overflow-x: hidden;
  overflow-y: auto;
}

.common-dropdown-filter {
  display: flex;
  align-items: center;
  gap: 1.25rem;
  .dropdown-text {
    color: var(--#{$variable-prefix}charcoal-gray);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.004rem;
  }
  .dropdown-icon {
    display: flex;
    align-items: baseline;
    gap: 0.625rem;
    color: var(--#{$variable-prefix}soft-plum-gray);
    font-weight: 400;
    svg {
      width: 0.875rem;
    }
    @extend .dropdown-text;
  }
}

.common-dropdown-filter-wrap {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 1.875rem;
  position: relative;
  padding-left: 1.875rem;
  &:before {
    content: "";
    position: absolute;
    left: 0;
    width: 0.063rem;
    height: 2.313rem;
    background-color: var(--#{$variable-prefix}gentle-sky);
  }
  .show {
    .dropdown-icon {
      svg {
        transform: rotate(180deg);
      }
    }
  }
  .common-dropdown-menu {
    max-height: 15.438rem;
    overflow-y: auto;
    overflow-x: hidden;
    &.dropdown-menu {
      margin-top: 1.313rem;
    }
  }
}