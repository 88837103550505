.custom-color-input {
  border: 0.063rem solid var(--#{$variable-prefix}bg-soft-plum-gray);
  padding: 1rem;
  border-radius: 0.25rem;
  label {
    color: var(--#{$variable-prefix}soft-plum-gray);
    background-color: var(--#{$variable-prefix}background-white);
    padding: 0.25rem 0.625rem;
    border-radius: 1.875rem;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.031rem;
    top: -0.125rem;
    left: 0.875rem;
  }
  input {
    border: none;
    padding: 0;
    width: 3rem;
    height: 1.25rem;
    flex-shrink: 0;
  }
}

.color-input-container {
  display: flex;
  align-items: center;
  gap: 0.813rem;
  .selected-color {
    color: var(--#{$variable-prefix}charcoal-gray);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1rem;
    letter-spacing: 0.031rem;
  }
}
