.form-body {
  background-color: var(--#{$variable-prefix}obsidian-black);
  max-width: 31.25rem;
  margin: 0 auto;
  h2 {
    color: var(--#{$variable-prefix}silver-gray);
    font-weight: 600;
    font-size: 2rem;
    line-height: 2.438rem;
  }
  label {
    color: var(--#{$variable-prefix}silver-gray);
  }
  .custom-input {
    height: 2.5rem;
    background-color: var(--#{$variable-prefix}gunmetal-gray);
    border-radius: 0.188rem;
    width: 100%;
    font-size: 0.875rem;
    line-height: 1.125rem;
    font-weight: 300;
    // color: var(--#{$variable-prefix}nightshade-gray);
    padding: 0.625rem 0.938rem;
    border: 0.063rem solid transparent;
  }
  .custom-selects {
    padding: 0 !important;
  }
  .custom-input:-webkit-autofill,
  .dark input:-webkit-autofill:hover,
  .dark input:-webkit-autofill:focus,
  .dark input:-webkit-autofill:active {
    -webkit-text-fill-color: var(--#{$variable-prefix}silver-gray) !important;
  }
}
