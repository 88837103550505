:root {
  @each $color, $value in $dynamic-var-colors {
    --#{$variable-prefix}#{$color}: #{$value};
  }
}

[data-color-mode="dark"] {
  color-scheme: dark;

  @each $color, $value in $dynamic-var-dark-colors {
    --#{$variable-prefix}#{$color}: #{$value};
  }
}
