// Prefix for :root CSS variables

$variable-prefix: bs- !default;

$dynamic-var-colors: (
  'primary-color': #6750a4,
  'secondary-color': #00aa3a,
  'background-white': #fff,
  'white-color': #fff,
  'bg-bright-green': #32ca50,
  'bright-green': #32ca50,
  'pastel-green': #bfe0c3,
  'bg-light-mint': #f1f8f4,
  'sky-blue': #94c1fe,
  'bg-light-blue': #e7effa,
  'bg-royal-blue': #317ce4,
  'royal-blue': #317ce4,
  'bg-pale-orange': #f7d386,
  'bg-cream-color': #fef8eb,
  'bg-coral-color': #ff8359,
  'bg-pale-pink-color': #fbefeb,
  'bg-orange-red-color': #ff5c25,
  'orange-red-color': #ff5c25,
  'bg-vivid-orange': #ff8a00,
  'vivid-orange': #ff8a00,
  'dark-red': #d30000,
  'bright-red': #e00000,
  'golden-yellow': #ffc341,
  'light-aluminum': #f6f6f6,
  'bg-light-aluminum': #f6f6f6,
  'bg-pale-silver': #f8f8f8,
  'slate-blue': #454b93,
  'cerulean-blue': #468be8,
  'light-lavender': #e9e7fd,
  'soft-lilac': #a695d4,
  'gentle-sky': #dae4ea,
  'light-platinum': #dfdfdf,
  'bg-dark-navy': #13171f,
  'dark-navy': #13171f,
  'charcoal-black': #1c1b1f,
  'obsidian-black': #1f2021,
  'jet-black': #171717,
  'scrollbar-thumb-color': #999,
  'scrollbar-track-color': #f8f8f8,
  'light-gray-text': #6c757d,
  'neutral-gray': #a5a5a5,
  'chill-gray': #cbc6c6,
  'silver-gray': #c0c2c4,
  'pearl-gray': #c0c0c0,
  'bg-charcoal-gray': #515151,
  'charcoal-gray': #515151,
  'bg-soft-plum-gray': #79747e,
  'soft-plum-gray': #79747e,
  'bg-cloud-gray': #d9d9d9,
  'cloud-gray': #d9d9d9,
  'medium-dark-gray': #4a4a4a,
  'graphite-gray': #303030,
  'bg-dark-gray': #21252c,
  'gunmetal-gray': #2e2e2e,
  'midnight-gray': #2b3140,
  'nightshade-gray': #333,
  'bg-night-sky-gray': #343434,
  'night-sky-gray': #343434,
  'dim-gray': #696969,
  'steel-gray': #7b7b7b,
  'ashen-gray': #8d8d8d,
  'pale-gray': #eee,
) !default;

$dynamic-var-dark-colors: (
  'background-white': #0d1117,
  'white-color': #13171f,
) !default;

// var(--#{$variable-prefix}background-white);

$font-path: '../font';
$dark-bg: #141515;
$dark-grey-bg: #1f2021;
$dark-grey-font: #c0c2c4;
$theme-blue: #468be8;
$theme-red: #ff4343;
$white-font: #fff;
$black-font: #222;
$panel-grey-txt: #7b7b7b;
$slate-grey: #2e2e2e;

$w-100: 100%;
$border-radius-full: 50%;
